import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';
import { Col, Row, Table } from 'react-bootstrap';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesSpaBro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide spa-bro'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Pure Fiction </li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_spabro.png"
            alt="Pure Fiction "
          />
        </div>
        <div className="page-details">
          <h1>Sparkle or Bronya - who is better?</h1>
          <h2>
            A comparison between Sparkle and Bronya showing who is better.
          </h2>
          <p>
            Last updated: <strong>29/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="sparkle" enablePopover />
          <HSRCharacter mode="icon" slug="bronya" enablePopover />
        </div>
        <p>
          <strong>TL;DR</strong> Who is better between Sparkle and Bronya comes
          down to the damage dealer of your choice and the specific setup you're
          using. To better understand the benefits and drawbacks of both, here
          is a full breakdown of what to expect from Sparkle and Bronya.
        </p>
        <SectionHeader title="Buffs" />
        <p>Let's compare their buffs first:</p>
        <div className="profile sparkle-bronya wheelchair buffs">
          <div className="box">
            <div className="team-container-outside">
              <HSRCharacter mode="icon" slug="sparkle" enablePopover />
            </div>
            <h5>Sparkle buffs</h5>
            <div className="inner">
              <ul>
                <li>
                  ATK%: <strong>15%</strong> (<strong>45%</strong> For Quantum
                  damage dealer),
                </li>
                <li>
                  DMG%: <strong>48%</strong>,
                </li>
                <li>
                  CRIT DMG: <strong>93%</strong> (assuming 200% CRIT DMG Sparkle
                  - can go higher with signature easily),
                </li>
                <li>
                  Action Boost: <strong>50%</strong>,
                </li>
                <li>
                  Efficacy: all buffs are active almost 100% of time (and FUA
                  characters benefit from them fully).
                </li>
              </ul>
            </div>
          </div>
          <div className="box">
            <div className="team-container-outside">
              <HSRCharacter mode="icon" slug="bronya" enablePopover />
            </div>
            <h5 className="bronya">Bronya Buffs</h5>
            <div className="inner">
              <ul>
                <li>
                  ATK%: <strong>55%</strong> (Normally 2/3rds uptime only),
                </li>
                <li>
                  DMG%: <strong>76%</strong>,
                </li>
                <li>
                  CRIT DMG: <strong>52%</strong> (Assuming 200% CRIT DMG Bronya
                  - normally 2/3rds uptime only),
                </li>
                <li>
                  Action Boost: <strong>100%</strong>,
                </li>
                <li>
                  Efficacy: most buffs only work on damage dealer's own action
                  (mostly doesn't affect FUA characters).
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p>
          When it comes to overall total buffs here are the standings (without
          Light Cones or Eidolons included):
        </p>
        <ul>
          <li>
            Sparkle (Quantum damage dealer): <strong>186%</strong>
          </li>
          <li>
            Bronya: <strong>183%</strong>
          </li>
          <li>
            Sparkle (Non-Quantum): <strong>156%</strong>
          </li>
        </ul>
        <p>
          But those are just the raw numbers and there are some important
          factors you need to keep in mind:
        </p>
        <ul>
          <li>
            Bronya <strong>cannot maintain her Ultimate buffs</strong> under
            most circumstances for more than 2/3's of damage dealers actions
            which reduces overall efficacy.
          </li>
          <li>
            The stat distribution of the buffs is different with Bronya offering
            more DMG% and Sparkle offering more CRIT DMG%. Depending on the
            damage dealer chosen one may prefer having higher of each of these
            stats, but as a general rule{' '}
            <strong>
              DMG% is generally the more desirable stats compared to CRIT DMG%.
            </strong>
          </li>
          <li>
            Sparkle only boosts her target by 50% half a turn where Bronya
            boosts by 100% a full turn. Something that looks like a landslide
            advantage for Bronya but comes with a catch because of our last
            reason - skill Point consumption and generation,
          </li>
          <li>
            <strong>Sparkle is completely skill point positive</strong>{' '}
            generating 0.33 skill points per action she performs but also grants
            a large influx of skill points at the start of combat.{' '}
            <strong>
              Bronya on the other hand is completely skill point negative
            </strong>{' '}
            at E0 without considering Light Cones (-1 skill points).
          </li>
        </ul>
        <SectionHeader title="Playstyles" />
        <h5>Playstyles - list</h5>
        <p>
          To full understand the impact of all points mentioned in the section
          above, it's time to compare the popular playstyles for both Bronya and
          Sparkle.
        </p>
        <p>First let's go over the Hyperspeed setups for both:</p>
        <div className="profile sparkle-bronya wheelchair">
          <div className="box">
            <div className="team-container-outside">
              <HSRCharacter mode="icon" slug="bronya" enablePopover />
            </div>
            <h5 className="bronya">Hyperspeed Bronya (160+ Speed)</h5>
            <div className="inner">
              <ul>
                <li>
                  Consumes 1 SP every damage dealer action (-1 SP economy),
                </li>
                <li>
                  Consumes 1 or more SP from damage dealer totaling at least -2
                  SP per action under most circumstances,
                </li>
                <li>
                  Grants all buffs above with 66% ultimate uptime (under most
                  circumstances),
                </li>
                <li>Grants current Speed to damage dealer (160 Speed),</li>
                <li>
                  Carry requires 0 Speed investment (can run full DMG subs and
                  DMG boots).
                </li>
              </ul>
              <p>
                <strong>Summary:</strong> Skill point restrictive to run but not
                impossible. Made easier with Bronya Signature and E1 but
                requires careful SP management and two other SP positive team
                members.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="team-container-outside">
              <HSRCharacter mode="icon" slug="sparkle" enablePopover />
            </div>
            <h5>Hyperspeed Sparkle (160+ Speed)</h5>
            <div className="inner">
              <ul>
                <li>
                  Generates 0.33 SP every damage dealer action (+0.33 SP
                  economy),
                </li>
                <li>
                  Consumes 1 or more SP from the damage dealer totaling at least
                  -0.66 SP per action under most circumstances,
                </li>
                <li>Grants 3 bonus SP at the start of battle,</li>
                <li>Grants all buffs above permanently,</li>
                <li>Grants current Speed to damage dealer (160 Speed),</li>
                <li>
                  Carry requires 0 Speed investment (can run full DMG subs and
                  DMG boots).
                </li>
              </ul>
              <p>
                <strong>Summary:</strong> Skill point friendly to run, enabling
                almost any damage dealer to freely spam their skill without
                restriction. Can also support less SP friendly team mates such
                as Fu Xuan or E0 Huo Huo as the main healer without issue.
              </p>
            </div>
          </div>
        </div>
        <p>Now let's compare the -1 Speed setups for both:</p>
        <div className="profile sparkle-bronya">
          <div className="box">
            <div className="team-container-outside">
              <HSRCharacter mode="icon" slug="bronya" enablePopover />
            </div>
            <h5 className="bronya">-1 Speed Bronya (full skill spam)</h5>
            <div className="inner">
              <ul>
                <li>
                  Consumes 1 SP every damage dealer action (-1 SP economy),
                </li>
                <li>
                  Consumes 2 or more SP from the damage dealer totaling at least
                  -3 SP per action under most circumstances,
                </li>
                <li>
                  Grants 50% uptime on Skill buff and incredibly limited uptime
                  of Ultimate,
                </li>
                <li>Grants double actions (2x Speed or double damage),</li>
                <li>
                  Requires the carry to be 1 Speed faster than Bronya (usually
                  135).
                </li>
              </ul>
              <p>
                <strong>Summary:</strong> This way of playing Bronya is almost
                impossible to run for most damage dealers for any sustained
                amount of cycles. Even while playing the most skill point
                positive supports, such as Luocha, outside of 2 exceptions:
                Blade and Jingliu who cheat the skill point system and consume
                very limited skill points of their own.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="team-container-outside">
              <HSRCharacter mode="icon" slug="bronya" enablePopover />
            </div>
            <h5 className="bronya">-1 Speed Bronya (Basic &gt; Skill)</h5>
            <div className="inner">
              <ul>
                <li>
                  Consumes 1 SP every damage dealer action (-1 SP economy),
                </li>
                <li>
                  Consumes 1 and gains 1 SP from the the damage dealer by using
                  a basic attack on the non-Bronya buffed action and the skill
                  on the Bronya buffed action. Totalling a net consumption of -1
                  SP under most circumstances,
                </li>
                <li>
                  Grants 50% uptime on Skill buff and incredibly limited uptime
                  of Ultimate,
                </li>
                <li>
                  Grants double actions (but not double damage) as half the
                  actions the damage dealer is taking are standard Basic
                  Attacks. Worth noting these are not useless and the skill is
                  being used in the fully buffed action but it still isn't close
                  to double damage,
                </li>
                <li>
                  Requires the carry to be 1 Speed faster than Bronya (usually
                  135).
                </li>
              </ul>
              <p>
                <strong>Summary:</strong> Most skill point friendly way to play
                Bronya and most flexible with DPS that aren't Blade or Jingliu
                causing her not to consume to many skill points and still
                benefit the DPS greatly but at the cost of overall damage.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="team-container-outside">
              <HSRCharacter mode="icon" slug="sparkle" enablePopover />
            </div>
            <h5>-1 Speed Sparkle</h5>
            <div className="inner">
              <ul>
                <li>
                  Generates 0.33SP every damage dealer action (+0.33 SP
                  economy),
                </li>
                <li>
                  Consumes 1.5 or more SP from the damage dealer totaling at
                  least -1.17SP per action under most circumstances (-3.5 SP
                  every 3 actions sparkle takes),
                </li>
                <li>Grants 3 bonus SP at the start of battle,</li>
                <li>
                  Grants Skill boost 66% of the time a high portion of the time
                  (&gt; 60%).
                </li>
                <li>Grants 50% More Actions,</li>
                <li>
                  Requires the carry to be 1 Speed faster than Sparkle (usually
                  135).
                </li>
              </ul>
              <p>
                <strong>Summary:</strong> Sacrifices Sparkle buff uptime and
                some of her skill point economy for converting the Speed
                transfer like interaction into 50% more actions.
              </p>
            </div>
          </div>
        </div>
        <h5>Playstyles - conclusion</h5>
        <StaticImage
          src="../../../images/starrail/generic/mason.jpg"
          alt="Numbers"
          className="image-inside"
        />
        <p>To conclude our comparison here is the meat of it:</p>
        <ul>
          <li>
            For characters that have no (or limited) access to DMG% increases,
            Bronya's buffs have an edge but for characters with high DMG% who
            might crave CRIT DMG% Sparkle performs better,
          </li>
          <li>
            The best supportive option of the builds listed above is the{' '}
            <strong>
              -1 Speed Bronya (full skill spam), but it requires the carry to
              use 2 skills and Bronya use 1 skill every action
            </strong>
            . And this is close to impossible to achieve outside of Blade and
            Jingliu but for these 2 characters Bronya is still the clear best in
            slot,
          </li>
          <li>
            The second best option is Hyperspeed Bronya / Hyperspeed Sparkle.
            They both feature high buff uptime (from Skill and Ultimate), while
            also boosting the action acceleration of the damage dealer and
            enabling him to use ATK% (or HP%) boots and focus his sub-stats away
            from Speed.
            <strong>
              Both setups are very close in performance and which is stronger
              numerically will depend on the damage dealer, sub-stats and Light
              Cones being used.
            </strong>{' '}
            But it's without a question that Hyperspeed Sparkle is substantially
            more skill point friendly than Hyperspeed Bronya, enabling the
            smooth use of sustains and supports that can't generate as many SP,
          </li>
          <li>
            Next in performance is the -1 Speed Sparkle build that can still
            sustain the skill spam playstyle of the damage dealer albeit with
            much more difficulty compared to the Hyperspeed Sparkle while still
            offering reasonable uptime on buffs and the 50% action multiplier,
          </li>
          <li>
            Last in performance is the Skill -&gt; Basic Bronya that asks the
            dps to use 1 basic and then 1 skill after Bronya boosts them cutting
            their damage in many cases substantially (also with the cost of
            having low uptime on Bronya's buffs). This composition is the most
            SP friendly of all Bronya's options but lacks the buff uptime and
            skill usage of all 4 options putting it in last place.
          </li>
        </ul>
        <p>
          You can see the comparison visualized here with a simulation of how
          each of the different builds impacts an endgame E0 S1 Imbibitor Lunae
          with Sparkle or Bronya as their only support over 8 actions. Observe
          the impact each build has on damage but also net skill points consumed
          by the combo (Sparkle's technique SP and Bronya Signature SP are
          included):
        </p>
        <Table striped bordered responsive className="sparkle-table">
          <thead>
            <tr>
              <th>Buffer Setup</th>
              <th>IL Setup</th>
              <th>Damage (raw)</th>
              <th>Difference (%)</th>
              <th>Net SP Consumed</th>
              <th>Total IL Actions</th>
              <th>Rotation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong className="Quantum">Hyperspeed Sparkle</strong> +
                Earthly Escapade S1
              </td>
              <td>E0S1 Imbibitor Lunae (IMG% Orb)</td>
              <td>1,537,957</td>
              <td>100%</td>
              <td>24</td>
              <td>13.68</td>
              <td>3EBA x 3 &gt; Ult</td>
            </tr>
            <tr>
              <td>
                <strong className="Quantum">Hyperspeed Sparkle</strong> + But
                the Battle Isn't Over S1
              </td>
              <td>E0S1 Imbibitor Lunae (ATK% Orb)</td>
              <td>1,504,923</td>
              <td>97.85%</td>
              <td>22</td>
              <td>13.68</td>
              <td>3EBA x 3 &gt; Ult</td>
            </tr>
            <tr>
              <td>
                <strong className="Wind">Hyperspeed Bronya</strong> + But the
                Battle Isn't Over S1
              </td>
              <td>E0S1 Imbibitor Lunae (ATK% Orb)</td>
              <td>1,504,653</td>
              <td>97.83%</td>
              <td>43</td>
              <td>13.68</td>
              <td>3EBA x 3 &gt; Ult</td>
            </tr>
            <tr>
              <td>
                <strong className="Quantum">Hyperspeed Sparkle</strong> + But
                the Battle Isn't Over S1
              </td>
              <td>E0S1 Imbibitor Lunae (IMG% Orb)</td>
              <td>1,494,335</td>
              <td>97.16%</td>
              <td>22</td>
              <td>13.68</td>
              <td>3EBA x 3 &gt; Ult</td>
            </tr>
            <tr>
              <td>
                <strong className="Wind">Hyperspeed Bronya</strong> + But the
                Battle Isn't Over S1
              </td>
              <td>E0S1 Imbibitor Lunae (IMG% Orb)</td>
              <td>1,489,753</td>
              <td>96.87%</td>
              <td>43</td>
              <td>13.68</td>
              <td>3EBA x 3 &gt; Ult</td>
            </tr>
            <tr>
              <td>
                <strong className="Quantum">-1 Speed Sparkle</strong> + Earthly
                Escapade S1
              </td>
              <td>E0S1 Imbibitor Lunae (IMG% Orb)</td>
              <td>1,462,055</td>
              <td>95.06%</td>
              <td>33</td>
              <td>17.08</td>
              <td>3EBA x 3 &gt; Ult</td>
            </tr>
            <tr>
              <td>
                <strong className="Quantum">-1 Speed Sparkle</strong> + But the
                Battle Isn't Over S1
              </td>
              <td>E0S1 Imbibitor Lunae (ATK% Orb)</td>
              <td>1,395,566</td>
              <td>90.74%</td>
              <td>33</td>
              <td>17.08</td>
              <td>3EBA x 3 &gt; Ult</td>
            </tr>
            <tr>
              <td>
                <strong className="Wind">-1 Speed Bronya</strong> + But the
                Battle Isn't Over S1
              </td>
              <td>E0S1 Imbibitor Lunae (ATK% Orb)</td>
              <td>1,210,316</td>
              <td>78.70%</td>
              <td>21</td>
              <td>22.78</td>
              <td>Normal Basic x2 3EBA x2 &gt; Ult</td>
            </tr>
          </tbody>
        </Table>
        <p>
          <strong>Disclaimer!</strong> We know that E0 Imbibitor Lunae isn't the
          best when played with Bronya, but we decided to use him as the example
          due to his excessive skill point consumption - which allows us to
          better showcase the difference between the setups.
        </p>
        <h5>Summary</h5>
        <p>
          <strong>Bronya is best for Blade and Jingliu</strong> as she can edge
          out Sparkle when both are being played in the Hyperspeed setup (when
          skill points aren't a concern) but Sparkle is close to (or even beats)
          Bronya in cases when CRIT DMG is valued over DMG% or when the damage
          dealer belongs to the Quantum element.
        </p>
        <p>
          Still, the{' '}
          <strong>
            flexibility of Sparkle's SP positive nature and her ability to boost
            the damage dealer's output outside of their own turn
          </strong>{' '}
          (which is very important for FUA characters like Jing Yuan, Dr. Ratio
          or Clara) pushes her usefulness and makes her the more universal
          support compared to Bronya - and by a good amount despite her maximum
          ceiling being lower for some characters.
        </p>
        <SectionHeader title="Videos" />
        <Row xs={1} xl={2} className="video-row">
          <Col>
            <YoutubeEmbed embedId="iSgsaIKB1wI" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesSpaBro;

export const Head: React.FC = () => (
  <Seo
    title="Sparkle or Bronya - who is better? | Honkai: Star Rail | Prydwen Institute"
    description="A comparison between Sparkle and Bronya showing who is better."
  />
);
